import styled from "styled-components";
import { StickyContainer, Sticky } from "react-sticky";
import { GlobalWrapper, TextWrapper } from "shared/Styles/Wrappers";
import { Paragraph } from "components/Typography";

import FileImgBlack from "assets/images/file.svg";

export const Container = styled(GlobalWrapper)`
  padding: 124px 0;
  overflow: hidden;
  position: relative;
  &::after {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.customColors.servicesBackground};
    z-index: -2;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 64px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0;
  }
`;

export const Wrapper = styled(TextWrapper)`
  display: flex;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 96px;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding-right: 64px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-right: 0;
    display: flex;
    align-items: center;
    text-align: center;
  }
`;

export const TextWrap = styled.div`
  position: relative;
  z-index: 4;
`;

export const TextHeader = styled.div`
  position: relative;
  padding-left: 52px;
`;

export const IconWrapper = styled.div`
  width: 36px;
  height: 28px;
  position: absolute;
  top: 4px;
  left: 0;
`;

export const Icon = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const Text = styled(Paragraph)`
  margin-top: 36px;
  font-size: 1.6rem;
  line-height: 1.6;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-top: 24px;
    font-size: 1.4rem;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 1.2rem;
  }
`;

export const StyledStickyContainer = styled(StickyContainer)`
  height: 100%;
  width: 100%;
  overflow: hidden;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    & > div > div {
      padding-bottom: 0 !important;
    }
  }
`;

export const StyledSticky = styled(Sticky)``;

export const StickyWrapper = styled.div`
  width: 100%;
  padding-top: 124px;
  padding-bottom: 36px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding-top: 96px;
    padding-bottom: 24px;
    padding: 96px 0 24px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: 0;
    transform: none !important;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    width: auto !important;
  }
`;

export const Download = styled.a`
  font-size: ${({ theme }) => theme.fonts.xxs};
  font-weight: 700;
  position: relative;
  transition: 0.2s background-color ease;
  padding: 10px 36px 10px 58px;
  background-color: ${({ theme }) => theme.colors.purple};
  color: #fff;
  display: inline-block;
  border-radius: 5px;
  margin-top: 36px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.button_hover};
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url(${FileImgBlack});
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 8px 24px 8px 48px;
    font-size: 1.4rem;
    &:before {
      width: 16px;
      height: 16px;
      left: 18px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 24px;
    font-size: 14px;
    padding: 10px 24px 10px 48px;
  }
`;

export const Column = styled.div`
  width: 50%;
  position: relative;
  &:nth-child(1) {
    display: flex;
    align-items: flex-end;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const CasesWrapper = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 48px;
    margin: 48px -24px 0 -24px;
  }
`;

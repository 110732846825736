import React from "react";
import { CaseCard } from "components/CaseCard";
import {
  Container,
  Wrapper,
  Column,
  CasesWrapper,
  StyledStickyContainer,
  StyledSticky,
  StickyWrapper,
} from "./styles";

export const Services = ({ children, data }) => {
  return (
    <Container>
      <Wrapper>
        <Column>
          <StyledStickyContainer>
            <StyledSticky>
              {({ style }) => (
                <StickyWrapper style={style}>{children}</StickyWrapper>
              )}
            </StyledSticky>
          </StyledStickyContainer>
        </Column>
        <Column>
          <CasesWrapper>
            {data.map((item, i) => (
              <CaseCard key={i} item={item} />
            ))}
          </CasesWrapper>
        </Column>
      </Wrapper>
    </Container>
  );
};

import React from "react";
import { TitleS } from "components/Typography";
import {
  ButtonWrapper,
  Icon,
  IconWrapper,
  StyledAnchorLink,
  StyledButton,
  StyledGatsbyLink,
  StyledLink,
  Text,
  TextHeader,
  TextWrap,
  Topic,
  UnorderedList,
} from "./styles";

const Container = ({ item, children }) => {
  if (item.slug) {
    return (
      <StyledGatsbyLink to={item.slug} title={item.title}>
        {children}
      </StyledGatsbyLink>
    );
  } else if (item.href) {
    return (
      <StyledLink
        href={item.href}
        title={item.title}
        target="_blank"
        rel="noopener"
      >
        {children}
      </StyledLink>
    );
  }

  return (
    <StyledAnchorLink to={item.anchor} title={item.title}>
      {children}
    </StyledAnchorLink>
  );
};

export const CaseCard = ({ item }) => {
  return (
    <Container item={item}>
      <TextWrap>
        <TextHeader>
          <IconWrapper>
            <Icon src={item.icon} alt={`ikona - ${item.title}`} />
          </IconWrapper>
          <TitleS as="h3" weight={800}>
            {item.title}
          </TitleS>
          {item.topics && (
            <UnorderedList>
              {item.topics.map((topic, key) => (
                <Topic key={key}>{topic}</Topic>
              ))}
            </UnorderedList>
          )}
        </TextHeader>
        <Text>{item.text}</Text>
        <ButtonWrapper>
          <StyledButton>Zobacz więcej</StyledButton>
        </ButtonWrapper>
      </TextWrap>
    </Container>
  );
};

import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { Paragraph } from "components/Typography";
import { Button } from "components/Buttons";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export const Text = styled(Paragraph)`
  margin: 48px 0;
  font-size: 1.8rem;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin: 24px 0;
    font-size: 1.4rem;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 1.2rem;
  }
`;

export const TextWrap = styled.div`
  position: relative;
  z-index: 4;
`;

export const TextHeader = styled.div`
  position: relative;
  padding-left: 52px;
`;

export const IconWrapper = styled.div`
  width: 36px;
  height: 28px;
  position: absolute;
  top: 4px;
  left: 0;
`;

export const Icon = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
`;

export const UnorderedList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  li {
    width: fit-content;
    text-transform: lowercase;
    margin: 0;
    color: ${({ theme }) => theme.colors.gray_black};
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    justify-content: initial;
    li {
      flex-wrap: nowrap;
    }
  }
`;

export const Topic = styled.li`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fonts.xxs};
  margin: 20px 8px 20px 0;
  list-style: none;
  color: ${({ theme }) => theme.colors.black};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.2rem;
    margin: 12px 6px 12px 0;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 1rem;
    margin: 12px 6px 12px 0;
  }
`;

export const ButtonWrapper = styled.div``;

export const StyledButton = styled(Button)``;

const defaultLink = css`
  padding: 36px 0 36px 36px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 36px;
  }
  ${Text} {
    margin-top: 24px;
    margin-bottom: 64px;
    display: block;
    font-size: 1.6rem;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 54vw;
    background-color: ${({ theme }) => theme.customColors.servicesCase};
    z-index: 3;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    width: 54vw;
    background-color: ${({ theme }) => theme.colors.gray};
    z-index: 3;
    border-left: 6px solid ${({ theme }) => theme.colors.purple};
    transition: 0.4s all ease;
  }
  &:hover {
    &:after {
      height: 100%;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 24px 0 24px 24px;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    h3 {
      font-size: 2.4rem;
    }
    ${Text} {
      margin-bottom: 36px;
      font-size: 1.4rem;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    padding: 24px 0 24px 24px;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    h3 {
      font-size: 2rem;
    }
    ${Text} {
      margin-bottom: 24px;
      font-size: 1.2rem;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    padding: 24px;
    &:before {
      width: 100%;
    }
    &:after {
      display: none !important;
    }

    ${Text} {
      max-width: 450px;
      width: 100%;
    }
  }
`;

export const StyledAnchorLink = styled(AnchorLink)`
  ${defaultLink}
`;

export const StyledLink = styled.a`
  ${defaultLink}
`;

export const StyledGatsbyLink = styled(Link)`
  ${defaultLink}
`;
